import { capitalizeFirstChar, cn } from "../../lib/utils";
import { ReactNode } from "react";
import BigNumber from "bignumber.js";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { addThousandSeparatorToStr } from "../../lib/number";

interface PaymentDetailsProps {
	investmentBy?: string;
	title?: string;
	investmentId?: string;
	updatedAt?: string | Date;
	createdAt?: string | Date;
	currency?: string;
	feeType?: string;
	accessFeePercentage?: number;
	managementFeePercentage?: number;
	managementFeeYears?: number;
	gstPercentage?: number;
	isGstAccessFee?: boolean;
	isGstManagementFee?: boolean;
	feeView?: string;
	price?: number;
	masterFundManagerFeesPerShare?: number;
	quantity?: number;
	investmentAmount?: number;
	isECF?: boolean;
	company?: string;
	className?: string;
}

interface LineProps {
	field: ReactNode;
	value: ReactNode;
	isText?: boolean;
}

const formatPrice = (number: number, dp: number = 2): string => {
	// Handle zero case with dynamic precision based on `dp`
	if (number === 0) return "0" + (dp > 0 ? "." + "0".repeat(dp) : "");

	let [integerPart, decimalPart = ""] = number.toString().split(".");

	// Truncate the decimal part by slicing, and pad if needed
	decimalPart = decimalPart.slice(0, dp).padEnd(dp, "0");

	// Combine integer part with decimal part and add thousand separators
	return addThousandSeparatorToStr(integerPart + (dp > 0 ? "." + decimalPart : ""));
};

const SummaryLine = ({ field, value, isText }: LineProps) => (
	<div className="flex justify-between">
		<div className="text-sm text-gray-600 flex-1 max-w-sm break-words">
			{field}
		</div>
		<div className={`text-sm pl-2 text-right ${isText && "text-sm"} text-[#383838] flex-2`}>
			{value}
		</div>
	</div>
);

const calculateFees = (
	baseAmount: BigNumber,
	accessFeePercentage: number = 0,
	managementFeePercentage: number = 0,
	managementFeeYears: number = 0,
	gstPercentage: number = 0,
	isGstAccessFee: boolean = false,
	isGstManagementFee: boolean = false,
) => {
	const accessFee = baseAmount
		.multipliedBy(accessFeePercentage)
	const managementFee = baseAmount
		.multipliedBy(managementFeePercentage)
		.multipliedBy(managementFeeYears)
	const totalFees = accessFee.plus(managementFee);
	const accessGstFee = isGstAccessFee ? accessFee.multipliedBy(gstPercentage) : BigNumber(0);
	const managementGstFee = isGstManagementFee ? managementFee.multipliedBy(gstPercentage) : BigNumber(0);
	const gstFee = accessGstFee.plus(managementGstFee);

	return { accessFee, managementFee, totalFees, gstFee };
};

const getGstFieldText = (feeView: string, feeType: string, isGstAccessFee: boolean, isGstManagementFee: boolean, accessFeePercentage: number, managementFeePercentage: number, managementFeeYears: number) => {
	if (feeView === "simplified") return "";

	if ((isGstAccessFee && accessFeePercentage > 0) && (isGstManagementFee && managementFeePercentage > 0 && managementFeeYears > 0)) {
		return ` on ${feeType} and management fee`;
	}

	if (isGstAccessFee && accessFeePercentage > 0) {
		return ` on ${feeType} fee`;
	}

	if (isGstManagementFee && managementFeePercentage > 0 && managementFeeYears > 0) {
		return ` on management fee`;
	}

	return "";
}

/**
 * Payment Details component for orders by either investment amount or by number of shares.
 * For the ECF / MY version please set isECF prop true
 * @param {string} investmentBy Decide whether the detail is for investment by investment_amount or number_of_shares. Defaults to investment_amount
 * @param {string} title Title of the payment details card (optional)
 * @param {string} investmentId ID of the payment request (optional)
 * @param {string} updatedAt Date for last updated at displayed under title (optional)
 * @param {string} createdAt Date for payment creation (optional)
 * @param {string} currency Currency used (defaults to USD if empty)
 * @param {string} feeType Access Fee type can be either origination fee or subscription fee (Defaults to origination fee)
 * @param {number} investmentAmount Investment amount, used/displayed if investment_by = investment amount.
 * @param {number} price Price per share, used/displayed if investment_by = number of shares.
 * @param {number} quantity Quantity of shares, used/displayed if investment_by = number of shares.
 * @param {number} masterFundManagerFeesPerShare Master Fund Manager Fees per share, used/displayed if investment_by = number of shares.
 * @param {number} accessFeePercentage Access Fee percentage value (origination / subscription fee)
 * @param {number} managementFeePercentage Management Fee percentage value (optional)
 * @param {number} gstPercentage GST Percentage fee if applicable (optional)
 * @param {boolean} isGstAccessFee If GST Chargeable for Access Fee (optional)
 * @param {boolean} isGstManagementFee If GST Chargeable for Management Fee (optional)
 * @param {string} feeView Display to user will be simplifed/breakdown mode. Defaults to simplified.
 * @param {boolean} isECF To display the ECF version of the component.
 * @param {string} company Display the company name of the deal, only used for ECF version (isECF)
 */
const PaymentDetails = ({
	investmentBy = "investment_amount",
	title,
	investmentId,
	updatedAt,
	createdAt,
	currency,
	feeType = "origination",
	price,
	quantity,
	masterFundManagerFeesPerShare,
	accessFeePercentage,
	managementFeePercentage,
	managementFeeYears,
	gstPercentage,
	isGstAccessFee,
	isGstManagementFee,
	feeView = "simplified",
	investmentAmount,
	company,
	isECF,
	className,
}: PaymentDetailsProps) => {
	//Prevent null/falsy values
	currency = currency || "USD";
	masterFundManagerFeesPerShare = masterFundManagerFeesPerShare || 0;
	price = price || 0;
	quantity = quantity || 0;
	investmentAmount = investmentAmount || 0;
	accessFeePercentage = accessFeePercentage || 0;
	managementFeePercentage = managementFeePercentage || 0;
	managementFeeYears = managementFeeYears || 0;
	gstPercentage = gstPercentage || 0;
	isGstAccessFee = isGstAccessFee === true;
	isGstManagementFee = isGstManagementFee === true;

	return (
		<div className={`rounded-lg border p-4 pt-0 w-[600px] ${className}`}>
			<div className="flex flex-col gap-4">
				<div>
					{title && <h5 className={cn("text-lg font-extrabold pt-4")}>{title}</h5>}
					{updatedAt && (
						<p className={cn("text-xs font-light text-gray-500")}>
							Last updated: {updatedAt?.toString()}
						</p>
					)}
				</div>
				{investmentId && (
					<SummaryLine
						field="Investment ID"
						value={
							<div>
								<div className="flex flex-row bg-slate-50 px-1 text-xs gap-1 rounded border items-center w-fit">
									<p className="truncate text-ellipsis w-[70px] leading-4">
										{investmentId}
									</p>
									<ClipboardDocumentIcon
										stroke="#1F2937"
										strokeWidth={2}
										onClick={() => navigator.clipboard.writeText(investmentId)}
										className="cursor-pointer"
										width={12}
										height={12}
									/>
								</div>
							</div>
						}
						isText
					/>
				)}
				{createdAt && (
					<SummaryLine field="Date" value={createdAt?.toString()} isText />
				)}
				{isECF ? (
					<ECFPaymentDetails
						price={price}
						quantity={quantity}
						currency={currency}
						company={company}
						accessFeePercentage={accessFeePercentage}
						managementFeePercentage={managementFeePercentage}
						managementFeeYears={managementFeeYears}
						feeType={feeType}
						feeView={feeView}
					/>
				) : investmentBy === "investment_amount" ? (
					<InvestmentAmountPaymentDetails
						investmentAmount={investmentAmount}
						currency={currency}
						feeType={feeType}
						accessFeePercentage={accessFeePercentage}
						managementFeePercentage={managementFeePercentage}
						managementFeeYears={managementFeeYears}
						gstPercentage={gstPercentage}
						isGstAccessFee={isGstAccessFee}
						isGstManagementFee={isGstManagementFee}
						feeView={feeView}
					/>
				) : (
					<NumberOfSharesPaymentDetails
						currency={currency}
						feeType={feeType}
						price={price}
						masterFundManagerFeesPerShare={masterFundManagerFeesPerShare}
						quantity={quantity}
						accessFeePercentage={accessFeePercentage}
						managementFeePercentage={managementFeePercentage}
						managementFeeYears={managementFeeYears}
						gstPercentage={gstPercentage}
						isGstAccessFee={isGstAccessFee}
						isGstManagementFee={isGstManagementFee}
						feeView={feeView}
					/>
				)}
			</div>
		</div>
	);
};

PaymentDetails.displayName = "Payment Details";

const NumberOfSharesPaymentDetails = ({
	price = 0,
	currency = "USD",
	feeType = "origination",
	masterFundManagerFeesPerShare = 0,
	quantity = 0,
	accessFeePercentage = 0,
	managementFeePercentage = 0,
	managementFeeYears = 0,
	gstPercentage = 0,
	isGstAccessFee = false,
	isGstManagementFee = false,
	feeView = "simplified",
}: PaymentDetailsProps) => {
	price = BigNumber(price).toNumber();
	const masterFundSubtotal = BigNumber(price)
		.multipliedBy(quantity)
		.plus(BigNumber(masterFundManagerFeesPerShare).multipliedBy(quantity))
	const { accessFee, managementFee, totalFees, gstFee } = calculateFees(
		masterFundSubtotal,
		accessFeePercentage,
		managementFeePercentage,
		managementFeeYears,
		gstPercentage,
		isGstAccessFee,
		isGstManagementFee,
	);
	const totalFee = masterFundSubtotal.plus(totalFees).plus(gstFee).toNumber();

	return (
		<>
			<SummaryLine
				field="Price"
				value={
					<>
						<span className="font-bold">
							{formatPrice(price)} {currency.toUpperCase()}
						</span>{" "}
						/ Share
					</>
				}
			/>
			{masterFundManagerFeesPerShare > 0 &&
				<SummaryLine
					field="Master fund manager fees"
					value={
						<>
							<span className="font-bold">
								{formatPrice(masterFundManagerFeesPerShare)}{" "}
								{currency.toUpperCase()}
							</span>{" "}
							/ Share
						</>
					}
				/>
			}
			<SummaryLine
				field="Quantity"
				value={<span >{formatPrice(quantity, 0)}</span>}
			/>
			<hr />
			<SummaryLine
				field="Master fund subtotal amount"
				value={`${formatPrice(
					masterFundSubtotal.toNumber(),
				)} ${currency.toUpperCase()}`}
			/>
			{feeView !== "simplified" ? (
				<>
					{accessFeePercentage > 0 && (
						<SummaryLine
							field={`${capitalizeFirstChar(feeType)} fee (${BigNumber(
								accessFeePercentage,
							).multipliedBy(100)}%)`}
							value={`${formatPrice(accessFee.toNumber())} ${currency.toUpperCase()}`}
						/>
					)}
					{managementFeePercentage > 0 && managementFeeYears > 0 && (
						<SummaryLine
							field={`Management fee (${BigNumber(
								managementFeePercentage,
							).multipliedBy(100)}% per annum for ${formatPrice(managementFeeYears, 0)} years)`}
							value={`${formatPrice(managementFee.toNumber())} ${currency.toUpperCase()}`}
						/>
					)}
				</>
			) : (
				<>
					{(accessFeePercentage > 0 || managementFeePercentage > 0) && (
						<SummaryLine
							field={`Total fees`}
							value={`${formatPrice(totalFees.toNumber())} ${currency.toUpperCase()}`}
						/>
					)}
				</>
			)}
			{gstPercentage > 0 && ((isGstAccessFee && accessFeePercentage > 0) || (isGstManagementFee && managementFeePercentage > 0 && managementFeeYears > 0)) && (
				<SummaryLine
					field={`Total local supply goods & services tax (${BigNumber(gstPercentage).multipliedBy(100)}%${getGstFieldText(feeView, feeType, isGstAccessFee, isGstManagementFee, accessFeePercentage, managementFeePercentage, managementFeeYears)})`}
					value={`${formatPrice(gstFee.toNumber())} ${currency.toUpperCase()}`}
				/>
			)}
			<hr />
			<div className="flex justify-between">
				<div className="text-sm font-bold text-gray-600">Total</div>
				<div className="text-sm font-bold text-[#383838]">{`${formatPrice(
					totalFee,
				)} ${currency.toUpperCase()}`}</div>
			</div>
		</>
	);
};

const InvestmentAmountPaymentDetails = ({
	investmentAmount = 0,
	currency = "USD",
	feeType = "origination",
	accessFeePercentage = 0,
	managementFeePercentage = 0,
	managementFeeYears = 0,
	gstPercentage = 0,
	isGstAccessFee = false,
	isGstManagementFee = false,
	feeView = "simplified",
}: PaymentDetailsProps) => {
	investmentAmount = BigNumber(investmentAmount).toNumber();
	const { accessFee, managementFee, totalFees, gstFee } = calculateFees(
		BigNumber(investmentAmount),
		accessFeePercentage,
		managementFeePercentage,
		managementFeeYears,
		gstPercentage,
		isGstAccessFee,
		isGstManagementFee,
	);
	const totalFee = BigNumber(investmentAmount).plus(totalFees).plus(gstFee).toNumber();

	return (
		<>
			<SummaryLine
				field="Investment amount"
				value={`${formatPrice(investmentAmount)} ${currency.toUpperCase()}`}
			/>
			{feeView !== "simplified" ? (
				<>
					{accessFeePercentage > 0 && (
						<SummaryLine
							field={`${capitalizeFirstChar(feeType)} fee (${BigNumber(
								accessFeePercentage,
							).multipliedBy(100)}%)`}
							value={`${formatPrice(accessFee.toNumber())} ${currency.toUpperCase()}`}
						/>
					)}
					{managementFeePercentage > 0 && managementFeeYears > 0 && (
						<SummaryLine
							field={`Management fee (${BigNumber(
								managementFeePercentage,
							).multipliedBy(100)}% per annum for ${formatPrice(managementFeeYears, 0)} years)`}
							value={`${formatPrice(managementFee.toNumber())} ${currency.toUpperCase()}`}
						/>
					)}
				</>
			) : (
				<>
					{(accessFeePercentage > 0 || managementFeePercentage > 0) && (
						<SummaryLine
							field={`Total fees`}
							value={`${formatPrice(totalFees.toNumber())} ${currency.toUpperCase()}`}
						/>
					)}
				</>
			)}
			{gstPercentage > 0 && ((isGstAccessFee && accessFeePercentage > 0) || (isGstManagementFee && managementFeePercentage > 0 && managementFeeYears > 0)) && (
				<SummaryLine
					field={`Total local supply goods & services tax (${BigNumber(gstPercentage).multipliedBy(100)}%${getGstFieldText(feeView, feeType, isGstAccessFee, isGstManagementFee, accessFeePercentage, managementFeePercentage, managementFeeYears)})`}
					value={`${formatPrice(gstFee.toNumber())} ${currency.toUpperCase()}`}
				/>
			)}
			<hr />
			<div className="flex justify-between">
				<div className="text-sm font-bold text-gray-600">Total</div>
				<div className="text-sm font-bold text-[#383838]">{`${formatPrice(
					totalFee,
				)} ${currency.toUpperCase()}`}</div>
			</div>
		</>
	);
};

const ECFPaymentDetails = ({
	price = 0,
	quantity = 0,
	currency = "USD",
	company,
	accessFeePercentage = 0,
	managementFeePercentage = 0,
	managementFeeYears = 0,
	feeType = "origination",
	feeView = "simplified",
}: PaymentDetailsProps) => {
	price = BigNumber(price).toNumber();
	const totalInvestment = BigNumber(price).multipliedBy(quantity);
	const { accessFee, managementFee, totalFees } = calculateFees(
		totalInvestment,
		accessFeePercentage,
		managementFeePercentage,
		managementFeeYears,
		0,
	);
	const totalFee = totalInvestment.plus(totalFees).toNumber();
	return (
		<>
			{company && <SummaryLine field="Company" value={company} isText />}
			<SummaryLine
				field="Price"
				value={
					<>
						<span className="font-bold">
							{currency.toUpperCase()} {formatPrice(price)}
						</span>{" "}
						/ Share
					</>
				}
			/>
			<SummaryLine
				field="Quantity"
				value={<span>{formatPrice(quantity, 0)} Shares</span>}
			/>
			<SummaryLine
				field="Total investment amount"
				value={`${currency.toUpperCase()} ${formatPrice(
					totalInvestment.toNumber(),
				)}`}
			/>
			{feeView !== "simplified" ? (
				<>
					{accessFeePercentage > 0 && (
						<SummaryLine
							field={`${capitalizeFirstChar(feeType)} fee (${BigNumber(
								accessFeePercentage,
							).multipliedBy(100)}%)`}
							value={`${formatPrice(accessFee.toNumber())} ${currency.toUpperCase()}`}
						/>
					)}
					{managementFeePercentage > 0 && managementFeeYears > 0 && (
						<SummaryLine
							field={`Management fee (${BigNumber(
								managementFeePercentage,
							).multipliedBy(100)}% per annum for ${formatPrice(managementFeeYears, 0)} years)`}
							value={`${formatPrice(managementFee.toNumber())} ${currency.toUpperCase()}`}
						/>
					)}
				</>
			) : (
				<>
					{(accessFeePercentage > 0 || managementFeePercentage > 0) && (
						<SummaryLine
							field={`Total fees`}
							value={`${formatPrice(totalFees.toNumber())} ${currency.toUpperCase()}`}
						/>
					)}
				</>
			)}
			<hr />
			<div className="flex justify-between">
				<div className="text-sm font-bold text-gray-600">Total</div>
				<div className="text-sm font-bold text-[#383838]">{`${currency.toUpperCase()} ${formatPrice(
					totalFee,
				)}`}</div>
			</div>
		</>
	);
};

export { PaymentDetails };
